<template>
    <div class="box">
        <el-row>
            <el-col :span='5'>
                <el-card class="box-card" shadow='always'>
                    <div slot="header" class="clearfix">
                        <span>个人信息</span>
                    </div>
                    <userAvatar></userAvatar>
                    <div v-for="o in 4" :key="o" class="text item">
                        {{'个人信息 ' + o }}
                        <div class="pull-right">admin</div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span='15' :push='1'>

                <el-card class="box-card" shadow='always'>
                    <div slot="header" class="clearfix">
                        <span>基本资料</span>
                    </div>
                    <el-tabs v-model="activeName" @tab-click="handleClick">

                        <el-tab-pane label="修改密码" name="first">
                            <el-form :model="ruleForm" status-icon :size='tabsize' :rules="rules" ref="ruleForm"
                                label-width="100px" class="demo-ruleForm">
                                <el-form-item label="旧密码" prop="pass">
                                    <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="新密码" prop="newpass">
                                    <el-input type="password" v-model="ruleForm.newpass" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="确认密码" prop="checkPass">
                                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off">
                                    </el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                                    <el-button @click="close()">关闭</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>

                    </el-tabs>


                </el-card>
            </el-col>
        </el-row>
        

    </div>
</template>

<script>
    import userAvatar from './userAvatar.vue'
    export default {
        data() {
            var checkPass = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('旧密码不能为空'));
                } else {
                    callback()
                }
            };
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.ruleForm.checkPass !== '') {
                        this.$refs.ruleForm.validateField('checkPass');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.ruleForm.newpass) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                ruleForm: {
                    pass: '',
                    newpass: '',
                    checkPass: '',
                },
                rules: {
                    pass: [{
                        validator: checkPass,
                        trigger: 'blur'
                    }],
                    newpass: [{
                        validator: validatePass,
                        trigger: 'blur'
                    }],
                    checkPass: [{
                        validator: validatePass2,
                        trigger: 'blur'
                    }],
                },
                activeName: 'first',
                imageUrl: ''
            };
        },
        components: {
            userAvatar
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let data = {
                            oldpwd: this.ruleForm.pass,
                            newpwd: this.ruleForm.newpass
                        }
                        this.$api.user.editpwd( data)
                            .then(res => {
                                if (res.status == 1) {
                                    this.$notify.success({
                                        title: 'success',
                                        message: '密码已修改请重新登录',
                                        showClose: false,
                                        duration: 2000,
                                    });
                                } else {
                                    this.$notify.error({
                                        title: 'error',
                                        message: res.msg,
                                        showClose: false,
                                        duration: 2000,
                                    });
                                }
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            close() {
                this.$router.push(this.$route.query.redirect)
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            }
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    }
</script>

<style scoped>
    .pwd {
        /* width: 500px; */
    }

    .box {
        padding: 3%;
    }

    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .el-upload {
        margin: 0 auto;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    
</style>